import { jsxs, jsx } from 'react/jsx-runtime';
import { PeopleCheckmark20Regular, ErrorCircle16Regular } from '@fluentui/react-icons';
import { useTheme, TextField, Link, Divider, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useApprovers } from '../../hooks/useApprovers.js';
import '../../config.js';
import '../../types/requestTypes.js';
import '../../types/itemTypes.js';
import '../../auth/config.js';
import '../../auth/AuthProvider.js';
import '@azure/msal-react';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '../../themes/light.theme.js';
import '../../store/store.js';
import 'redux-persist/integration/react';
import '../../store/slices/applicationSlice.js';
import '../../store/slices/downloadFileSlice.js';
import '../../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../../constants/messages.js';
import '../../services/requestApi.js';
import '../../services/userApi.js';
import '../../services/itemApi.js';
import '../../services/requestDetailsApi.js';
import '@pdftron/webviewer';
import 'lodash';
import '../item/utils.js';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../../store/thunks/downloadFileThunk.js';
import { Box } from '../Box.js';
import { CopyToClipboardButton } from '../CopyToClipboardButton.js';
import { DetailsRow } from '../DetailsRow.js';
import { EditApprovers } from '../EditApprovers.js';
import { CustomActionModal } from './CustomActionModal.js';
import { ActionButton } from './components/ActionButton.js';

const DisposalApprovalDialog = ({ title = 'Request Disposal Approval', disposalRequest, approvers, open, onClose, onAction, currentSelectedApprovers, }) => {
    //states and hooks
    const [name, setName] = useState(disposalRequest?.Name);
    const [comment, setComment] = useState(disposalRequest?.RequestComments);
    useEffect(() => {
        disposalRequest?.ApproverResponses?.value.forEach((_approverResponse) => {
            if (!currentSelectedApprovers.some((e) => e.ID === _approverResponse.Approver.ID))
                currentSelectedApprovers.push({
                    _Display: _approverResponse.Approver?.DisplayName,
                    ID: _approverResponse.Approver?.ID,
                    Department: '',
                    Status: _approverResponse?.Status,
                    ApproverId: _approverResponse?.ID,
                });
        });
    }, [approvers]);
    const { selectedApprovers, setSelectedApprovers, isApproversListValid, setIsApproversListValid, } = useApprovers(currentSelectedApprovers);
    const theme = useTheme();
    //effects
    useEffect(() => {
        setSelectedApprovers(currentSelectedApprovers);
    }, [currentSelectedApprovers]);
    useEffect(() => {
        setName(disposalRequest?.Name);
        setComment(disposalRequest?.RequestComments);
    }, [disposalRequest]);
    return (jsxs(CustomActionModal, { title: title, onClose: () => onClose(selectedApprovers), open: open, icon: jsx(PeopleCheckmark20Regular, {}), actionButton: jsx(ActionButton, { onAction: onAction, name: name, comment: comment, selectedApprovers: selectedApprovers, setIsValidCondition: (approvers) => approvers.length > 0, setValidity: setIsApproversListValid, label: 'Request Approval' }), children: [jsx(DetailsRow, { label: 'Name', data: jsx(Box, { alignItems: 'start', background: 'none', style: {
                        overflow: 'hidden',
                        width: '100%',
                    }, children: jsx(TextField, { sx: { height: '3rem', width: '100%' }, placeholder: 'Enter name', onChange: (event) => setName(event.target.value), value: name }) }), size: 'small' }), jsx(DetailsRow, { label: 'Disposal Action', data: disposalRequest?.DisposalAction, size: 'small' }), jsx(DetailsRow, { label: 'Web Link', data: jsxs(Box, { background: 'none', direction: 'row', alignItems: 'center', style: {
                        marginTop: '-0.2rem',
                    }, children: [jsx(Link, { variant: 'body2', sx: {
                                width: '90%',
                                display: 'block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }, href: disposalRequest.EncompaasUrl, children: disposalRequest.EncompaasUrl }), jsx(CopyToClipboardButton, { text: disposalRequest.EncompaasUrl, color: theme.palette.primary.main })] }), size: 'small', alignData: true }), jsx(Divider, { sx: {
                    margin: '1.5rem 0',
                } }), jsx(EditApprovers, { approvers: approvers, setSelectedApprovers: setSelectedApprovers, selectedApprovers: selectedApprovers, isApproversListValid: isApproversListValid, setIsApproversListValid: setIsApproversListValid, isDisposalRequestRejected: disposalRequest?.Status === 'Rejected' }), !isApproversListValid && (jsxs(Box, { background: 'none', color: 'red', direction: 'row', style: {
                    marginTop: '0.75rem',
                }, children: [jsx(ErrorCircle16Regular, { color: theme.palette.warning.main, style: {
                            marginRight: '.3rem',
                        } }), jsx(Typography, { variant: 'body3', color: theme.palette.warning.main, children: "Please specify approvers" })] })), jsx(Typography, { variant: 'body1', mt: 4, sx: {
                    color: theme.palette.info.dark,
                }, children: "Comment" }), jsx(TextField, { sx: {
                    marginTop: '0.5rem',
                }, fullWidth: true, maxRows: 5, minRows: 5, multiline: true, onChange: (event) => setComment(event.target.value), value: comment })] }));
};

export { DisposalApprovalDialog };
